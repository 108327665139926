<template>
  <base-section
    id="portfolio-desc"
    class="px-5"
  >
    <base-section-heading
      title="Implemented Projects"
    >
    </base-section-heading>
      <v-row>
        <v-col
          v-for="(info, n) in information"
          :key="n"
          cols="12"
          md="4"
          sm="12"
        >
          <v-card
            class="mx-auto black--text grey lighten-2"
          >
            <v-img
              :src="info.src"
              :height="$vuetify.breakpoint.mdAndUp ? 300 : 200"
            ></v-img>

            <v-card-title>
              {{info.title}}
            </v-card-title>
            <v-card-text
              class="black--text v-space_control"
            >
              {{info.text}}
            </v-card-text>

          </v-card>
        </v-col>
      </v-row>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionPortfolioDesc',
    data () {
      return {
        information: [
          {
            title: 'Storage of Air Conditioning Parts',
            text: 'Warehouse, WMS software',
            src: require('@/assets/ronghao_portfolio1.jpg'),
          },
          {
            title: 'Wuhan Lear Warehouse',
            text: 'Warehouse, WMS software, Auxiliary lines',
            src: require('@/assets/ronghao_portfolio2.jpg'),
          },
          {
            title: 'LG Warehouse',
            text: 'Warehouse, WMS software, Auxiliary lines',
            src: require('@/assets/ronghao_portfolio3.jpg'),
          },
          {
            title: 'Beijing Lear Warehouse',
            text: 'AGV, WIFI, Software, Rack',
            src: require('@/assets/ronghao_portfolio5.jpg'),
          },
          {
            title: 'Chongqing Lear Warehouse',
            text: 'AGV, WIFI, Software',
            src: require('@/assets/ronghao_portfolio6.jpg'),
          },
          {
            title: 'Shen Yang SJA Warehouse',
            text: 'AGV, WIFI, Software',
            src: require('@/assets/ronghao_portfolio7.jpg'),
          },
          {
            title: 'To Be Updated',
            text: '',
            src: require('@/assets/ronghao_portfolio4.jpg'),
          },
        ],
      }
    },
  }
</script>
<style type="text/css">
.v-card__text {
  letter-spacing: 0.0rem;
}
</style>
